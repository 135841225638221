import { useRef, useEffect, useState } from 'react';
import type { MouseEvent } from 'react';

import loadable from '@loadable/component';
import classnames from 'classnames';
import { GLOBAL } from 'saddlebag-browser';

import BpkButton, {
  SIZE_TYPES,
} from '@skyscanner/backpack-web/bpk-component-button';
import {
  withButtonAlignment,
  withRtlSupport,
} from '@skyscanner/backpack-web/bpk-component-icon';
import ArrowUpIcon from '@skyscanner/backpack-web/bpk-component-icon/sm/long-arrow-up';

import {
  ACTION_TYPE,
  COMPONENT_ACTION,
  COMPONENT_NAME,
  LOAD_STATUS,
} from '../../constants';
import logMiniEventHelper from '../../mini-event/logMiniEventHelper';

import type { HorizontalNavigationProps } from 'common-types/types/HorizontalNavigation';

import STYLES from './HorizontalNavigation.module.scss';

const AlignedArrowUpIcon = withButtonAlignment(withRtlSupport(ArrowUpIcon));

const IntersectionObserverWrapper = loadable(
  () => import('../IntersectionObserverWrapper/IntersectionObserverWrapper'),
);

const $window = GLOBAL.getWindow();
const $document = GLOBAL.getDocument();
const FIXED_SPACING = 20;

const HorizontalNavigation = (props: HorizontalNavigationProps) => {
  const { button, firstItemActiveOnTop, isHiddenWhenNotStickToTop, navList } =
    props;
  const placeholderRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const itemsRef = useRef<HTMLDivElement>(null);
  const [isSticky, setSticky] = useState(false);
  const [navListToRender, setNavListToRender] = useState([
    { id: '', content: '' },
  ]);
  // Set default active status to be not active
  const [selectedIndex, setSelectedIndex] = useState(-1);

  const [hideBackToTopButton, setHideBackToTopButton] = useState(false);

  useEffect(() => {
    logMiniEventHelper({
      action_type: ACTION_TYPE.COMPONENT_LOADED,
      component_name: COMPONENT_NAME.HORIZONTAL_NAVIGATION,
      load_status: LOAD_STATUS.LOADED,
    });
  }, []);

  useEffect(() => {
    const handleResize = () => {
      const windowWidthLimit = 768;
      if ($window.innerWidth < windowWidthLimit) {
        setHideBackToTopButton(true);
      } else {
        setHideBackToTopButton(false);
      }
    };

    $window.addEventListener('resize', handleResize);

    return () => {
      $window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    // Should render navs that the relevant components are already rendered on the page
    // The code:
    // https://github.skyscannertools.net/martech/falcon/blob/54e5c2b1ec4a1c7fd5c53f356cee42c9c18b0bf6/client/src/components/FeatureContainer/FeatureContainer.tsx#L33
    // shows that the FeatureContainer always puts the React component node as a child node of the <div id=REACT_COMPONENT_NAME>.
    // As a result, we should not only check if element exists by id, but also check whether the element has child nodes.
    // In this way we cover the cases where the app returns non-null props and the client returns null element by some empty check.
    // For example:
    // client: https://github.skyscannertools.net/martech/falcon/blob/808da5e293be22b73946e195dd8b6090aeb3a67d/client/src/components/HotelsByStarRating/HotelsByStarRating.tsx#L12
    // app: https://github.skyscannertools.net/martech/falcon/blob/d4302259fde8d1075fa50fa6374a9589650a8aa2/app/components/localRenderers/hotelByStarRating/index.ts#L315
    setNavListToRender(
      navList.filter((component) => {
        const element = $document.getElementById(component.id);
        if (!element) {
          return false;
        }
        return element.hasChildNodes();
      }),
    );
  }, [navList]);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      if (entry.boundingClientRect.height === 0) {
        setSticky(false);
      }
    });

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }
    return () => {
      observer.disconnect();
    };
  }, [containerRef]);

  useEffect(() => {
    const loadSticky = () => {
      if (placeholderRef.current) {
        const { top } = placeholderRef.current.getBoundingClientRect();

        if (top <= 0) {
          setSticky(true);
        } else {
          setSticky(false);
          setSelectedIndex(-1);
        }
      }
    };

    const loadSelectedIndex = () => {
      // Get container height once
      let containerHeight: number;
      if (containerRef.current) {
        const { height } = containerRef.current.getBoundingClientRect();
        containerHeight = height;
      }

      let navigationBarTop: number;
      if (placeholderRef.current) {
        navigationBarTop = placeholderRef.current.getBoundingClientRect().top;
      }

      const itemActiveStates: boolean[] = [];
      navListToRender.forEach((nav, index) => {
        if (!nav || !nav.id) {
          return;
        }

        const elm = $document.getElementById(nav.id);
        if (elm) {
          const rect = elm.getBoundingClientRect();
          if (navigationBarTop > 0 && firstItemActiveOnTop && index === 0) {
            itemActiveStates.push(true);
            setSelectedIndex(0);
            itemsRef.current?.scrollTo({ left: 0 });
          } else if (
            rect.top <= containerHeight + FIXED_SPACING &&
            rect.bottom >= containerHeight &&
            rect.height !== 0
          ) {
            itemActiveStates.push(true);
            index !== selectedIndex && setSelectedIndex(index);

            const navItem = $document.getElementById(
              `${nav.id}HorizontalNavigation__item`,
            );
            if (navItem) {
              navItem.scrollIntoView({
                inline: 'center',
              });
            }
          } else {
            itemActiveStates.push(false);
          }
        }
      });

      // When no nav ids are matched, remove all active states
      if (
        itemActiveStates.length &&
        itemActiveStates.every((itemState) => !itemState)
      ) {
        setSelectedIndex(-1);
      }
    };

    const handleScroll = () => {
      loadSticky();
      loadSelectedIndex();
    };

    handleScroll();

    $window.addEventListener('scroll', handleScroll);
    return () => {
      $window.removeEventListener('scroll', handleScroll);
    };
  }, [firstItemActiveOnTop, navListToRender, selectedIndex]);

  const handleClick =
    (navIndex: number) => (evt: MouseEvent<HTMLAnchorElement>) => {
      evt.preventDefault();

      navIndex !== selectedIndex && setSelectedIndex(navIndex);

      logMiniEventHelper({
        action_type: ACTION_TYPE.COMPONENT_CLICKED,
        component_name: COMPONENT_NAME.HORIZONTAL_NAVIGATION,
        component_action:
          COMPONENT_ACTION.HORIZONTAL_NAVIGATION.HORIZONTAL_NAVIGATION_CLICKED,
        nav_info: {
          anchor_id: navListToRender[navIndex].id,
          content: navListToRender[navIndex].content,
          index: navIndex,
        },
      });

      const target = $document.getElementById(navListToRender[navIndex].id);
      if (target) {
        if (containerRef.current) {
          evt.currentTarget.scrollIntoView({
            inline: 'end',
          });

          const { height } = containerRef.current.getBoundingClientRect();
          const scrollY =
            target.getBoundingClientRect().top + $window.pageYOffset - height;
          // Ensure scroll to the exact component
          const boxShadowHeight = 3;
          $window.scrollTo({
            top: scrollY + boxShadowHeight - FIXED_SPACING,
            behavior: 'smooth',
          });
        }
      }
    };

  const onButtonClick = (btn: string) => {
    logMiniEventHelper({
      action_type: ACTION_TYPE.COMPONENT_CLICKED,
      component_name: COMPONENT_NAME.HORIZONTAL_NAVIGATION,
      component_action:
        COMPONENT_ACTION.HORIZONTAL_NAVIGATION
          .HORIZONTAL_NAVIGATION_BUTTON_CLICKED,
      link_info: {
        content: btn,
        url: 'top',
      },
    });
    $window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  if (!navListToRender?.length) return null;
  return (
    <div
      ref={placeholderRef}
      className={
        isHiddenWhenNotStickToTop
          ? STYLES.HorizontalNavigation
          : classnames(
              STYLES.HorizontalNavigation,
              STYLES.HorizontalNavigation__height_holder,
            )
      }
    >
      <div
        ref={containerRef}
        className={classnames(STYLES.HorizontalNavigation__container, {
          [STYLES.HorizontalNavigation__fixed]: isSticky,
          [STYLES.HorizontalNavigation__static]: !isSticky,
          [STYLES.HorizontalNavigation__hidden]:
            isHiddenWhenNotStickToTop && !isSticky,
        })}
      >
        <div className={STYLES.HorizontalNavigation__items} ref={itemsRef}>
          {navListToRender.map(
            (nav, index) =>
              nav.id && (
                <a
                  key={nav.id}
                  href={`#${nav.id}`}
                  id={`${nav.id}HorizontalNavigation__item`}
                  className={classnames(STYLES.HorizontalNavigation__item, {
                    [STYLES['HorizontalNavigation__item-selected']]:
                      selectedIndex === index,
                  })}
                  onClick={handleClick(index)}
                >
                  {nav.content}
                </a>
              ),
          )}
          {button && isSticky && (
            <div
              className={classnames(STYLES.HorizontalNavigation__button, {
                [STYLES['HorizontalNavigation__button-hidden']]:
                  hideBackToTopButton,
              })}
            >
              <BpkButton
                primary
                size={SIZE_TYPES.small}
                href=""
                rel="nofollow"
                onClick={() => onButtonClick(button)}
              >
                {button}
                <AlignedArrowUpIcon
                  className={STYLES['HorizontalNavigation__arrow-up-icon']}
                />
              </BpkButton>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default (props: HorizontalNavigationProps) => (
  <IntersectionObserverWrapper
    onElementSeen={() =>
      logMiniEventHelper({
        action_type: ACTION_TYPE.COMPONENT_VISIBLE,
        component_name: COMPONENT_NAME.HORIZONTAL_NAVIGATION,
      })
    }
  >
    <HorizontalNavigation {...props} />
  </IntersectionObserverWrapper>
);
